<template>
    <div class="fastFoodNumCode" :style="font_size">
        <!-- 数字键盘 -->
        <input type="text" ref="myInput" v-show="false" :value="codeVal" @input="inputEvent"> 
        <div class="box">
            <div class="left_3">
                <div @click="changeVal('￥10')" :style="h_style" v-if="showMoney">￥10</div>
                <div @click="changeVal('￥20')" :style="h_style" v-if="showMoney">￥20</div>
                <div @click="changeVal('￥50')" :style="h_style" v-if="showMoney">￥50</div>
                <div @click="changeVal('1')" :style="h_style">1</div>
                <div @click="changeVal('2')" :style="h_style">2</div>
                <div @click="changeVal('3')" :style="h_style">3</div>
                <div @click="changeVal('4')" :style="h_style">4</div>
                <div @click="changeVal('5')" :style="h_style">5</div>
                <div @click="changeVal('6')" :style="h_style">6</div>
                <div @click="changeVal('7')" :style="h_style">7</div>
                <div @click="changeVal('8')" :style="h_style">8</div>
                <div @click="changeVal('9')" :style="h_style">9</div>
                <div @click="changeVal('00')" :style="h_style">00</div>
                <div @click="changeVal('0')" :style="h_style">0</div>
                <div @click="changeVal('.')" :style="h_style">.</div>
            </div>
            <div class="right">
                <div @click="changeVal('￥100')" :style="h_style" v-if="showMoney">￥100</div>
                <div @click="changeVal('delete')" :style="h_style">
                    <span :style="font_size" class="iconfont icon-delete"></span>
                </div>
                <div @click="changeVal('clear')" :style="h_style">清空</div>
                <div @click="okHandle" class="bgColor" :style="hh_style">{{okText}}</div>
            </div>
        </div>
        
    </div>
</template>
<script>
export default {
    name:'fastFoodNumCode',
    model:{
        prop:'codeVal',  // 对应到props
        event:'change'
    },
    props:{
        height:{
            default:28,
            type:Number
        },
        showMoney:{
            default:false,
            type:Boolean
        },
        codeVal:{
            default:null,
            type:[String,Number]
        },
        focus_select:{
            default:false,
            type:Boolean
        },
        okText:{
            default:'确定',
            type:String
        }
    },
    data(){
        return{
            h_style:{
                height:'7vh',
                lineHeight:'7vh'
            },
            hh_style:{
                height:'14vh',
                lineHeight:'12vh',
                color: '#ffffff'
            },
            font_size:{
                fontSize:'2.8vh'
            },
            select_text:false
        }
    },
    mounted(){
        this.font_size = {
            fontSize: this.height / 14 + 'vh'
        }
        if(this.showMoney){
            this.h_style = {
                height: this.height / 5 + 'vh',
                lineHeight: this.height / 5 + 'vh',
            }
            this.hh_style = {
                height:  this.height / 5 * 2 + 'vh',
                lineHeight:  this.height / 5 * 2 + 'vh',
                color: '#ffffff'
            }
        }else{
            this.h_style = {
                height: this.height / 4 + 'vh',
                lineHeight: this.height / 4 + 'vh',
            }
            this.hh_style = {
                height:  this.height / 4 * 2 + 'vh',
                lineHeight:  this.height / 4 * 2 + 'vh',
                color: '#ffffff'
            }
        }
    },
    methods:{
        changeVal(val){
            let valData = ''
            if(val == '￥10' || val == '￥20' || val == '￥50' || val == '￥100'){
                valData = val.slice(1)
            }else if(val == 'delete'){
                if(this.focus_select){
                    valData = ''
                }else{
                    valData = this.codeVal.slice(0 , this.codeVal.length - 1)
                }
            }else if(val == 'clear'){
                valData = ''
            }else if(val == '.'){
                if(this.focus_select){
                    valData = val
                }else{
                    let i = this.codeVal.indexOf('.')
                    console.log(i);
                    if(i < 0){
                    valData = this.codeVal + '.'
                    }else{
                        valData = this.codeVal
                        this.$message.warning('小数点不可多次输入')
                    }
                }
            }else{
                if(this.focus_select){
                    valData = val
                }else{
                    valData = this.codeVal + val
                }
            }

            this.$refs.myInput.value = valData
            let evt = document.createEvent('HTMLEvents') 
            evt.initEvent('input', true, true)
            this.$refs.myInput.dispatchEvent(evt)
        },
        inputEvent(e){
            this.$emit('change',e.target.value)
        },
        okHandle(){
            this.$emit('okHandle')
        },
    }
}
</script>
<style lang="less" scoped>
.fastFoodNumCode{
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    font-weight: 700;
    .box{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        border-left: 1px solid #a6a6a6;
        border-top: 1px solid #a6a6a6;
        cursor: pointer;
        .left_3{
            flex: 3;
            >div{
                display: inline-block;
                width: 33.33%;
                border-right: 1px solid #a6a6a6;
                border-bottom: 1px solid #a6a6a6;
                box-sizing: border-box;
                // &:hover{
                //     background: #D95E5830;
                // }
                &:focus, &:active{
                    background: #D95E5830;
                    // box-shadow: 0px 0px 10px 0px #D95E5880;
                }
            }
        }
        .right{
            flex: 1;
            >div{
                border-right: 1px solid #a6a6a6;
                border-bottom: 1px solid #a6a6a6;
                box-sizing: border-box;
                &:focus, &:active{
                    background: #D95E5830;
                    // box-shadow: 0px 0px 5px 0px #D95E5880;
                }
            }
        }
    }
    
    
}
</style>