<template>
    <div class="prepackaged">
        <div class="title">预包装标签</div>
        <div class="searchForm">
            <span>查询商品打印：</span>
            <a-input v-model="value" placeholder="请输入商品名称/条码" @pressEnter="searchHandle"/>
            <a-button class="searchBtn" @click="searchHandle">查询</a-button>
        </div>
        <div class="goodsBox">
            <div class="goods_item" v-for="item in tableData" :key="item.goods_id" @click="clickGoods(item)">
                <div class="img_box">
                    <img :src="item.preview_url" alt="">
                </div>
                <div class="name">{{item.goods_name}}</div>
                <div class="price">￥{{item.goods_price}}</div>
                <div v-if="item.is_weighing == 2" class="weighing">称重</div>
            </div>
        </div>
        <div class="page">
            <a-pagination v-model="searchForm.current"
             :total="searchForm.total" @change="pageChange"
             :pageSize="searchForm.page_size" show-less-items />
        </div>
        <a-modal v-model="visible" width="50vw" :title="'预包装：标签打印'" :footer="null">
            <div class="listData">
                <div class="title">商品基本信息</div>
                <div class="bigForm">
                    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
                        <a-form-model-item label="商品名称" prop="goods_name">
                            <a-input v-model="form.goods_name" @change="inputChange('goods_name')" @focus="keyboards = 'goods_name'"/>
                        </a-form-model-item>
                        <a-form-model-item label="规格选择" prop="spec_content" v-if="form.spec_type == 20">
                            <a-radio-group v-model="form.spec_content" button-style="solid" @change="typeChange">
                                <a-radio-button :value="item.spec_name" v-for="(item,i) in guigeArr" :key="i">{{item.spec_name}}</a-radio-button>
                            </a-radio-group>
                        </a-form-model-item>
                        <a-form-model-item :label="form.is_weighing == 2 ? '重量' : '计量'" prop="count">
                            <a-input v-model="form.count" v-if="form.is_weighing == 2" suffix="kg" @change="inputChange('count')" :disabled="disabled" @focus="keyboards = 'count'"/>
                            <a-input v-model="form.count" v-else @change="inputChange('count')" @focus="keyboards = 'count'"/>
                        </a-form-model-item>
                        <a-form-model-item label="零售价格" prop="price">
                            <a-input v-model="form.price" prefix="￥" @change="inputChange('price')" @focus="keyboards = 'price'"/>
                        </a-form-model-item>
                        <a-form-model-item label="小计金额" prop="total_price">
                            <a-input v-model="form.total_price" prefix="￥" @change="inputChange('total_price')" @focus="keyboards = 'total_price'"/>
                        </a-form-model-item>
                        <a-form-model-item label="打印份数" prop="num">
                            <div class="jibuqi">
                                <a-icon type="minus" @click="numChange('jian')"/>
                                <a-input v-model="form.num" @change="inputChange('num')" @focus="keyboards = 'num'"/>
                                <a-icon type="plus" @click="numChange('jia')" />
                            </div>
                        </a-form-model-item>
                    </a-form-model>
                    <numCode :okText="'确定打印'" v-model="formVal" @change="codeChange"  @okHandle="handleOk"></numCode>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import { goodsList } from '@/request/business.js'
import numCode from '@/components/keycode/fastFoodNumCode.vue'
export default {
    name:'prepackaged',
    data(){
        return{
            formVal:'',
            keyboards:'',
            value:'',
            tableData:[],
            visible:false,
            searchForm:{
                current:1,
                total:0,
                page_size:21
            },
            checkedGoods:{},
            form:{},
            rules:{
                goods_name:[
                    { required: true, message: '请输入商品名称', trigger: ['change','blur'] },
                ],
                spec_content:[
                    { required: true, message: '请选择规格', trigger: ['change','blur'] },
                ],
                count:[
                    { required: true, message: '请输入商品重量', trigger: ['change','blur'] },
                ],
                price: [
                    { required: true, message: '请输入零售价格', trigger: ['change','blur'] },
                ],
                total_price: [
                    { required: true, message: '请输入小计金额', trigger: ['change','blur'] },
                ],
                num: [
                    { required: true, message: '请输入打印份数', trigger: ['change','blur'] },
                ],
            },
            guigeArr:[],
            times:"",
            disabled:false
        }
    },
    watch:{
        keyboards(val){
            this.formVal = this.form[val]
        },
        visible(val){
            if(!val){
                clearInterval(this.times);
                this.checkedGoods = {}
                this.formVal = ''
                this.keyboards = ''
                this.guigeArr = []
            }else{
                const instllObject= JSON.parse(localStorage.getItem('install'))?JSON.parse(localStorage.getItem('install')):{use:2,factory:undefined,type:undefined,com:undefined,potter:undefined,}
                if(instllObject.use==1 && this.form.is_weighing == 2){
                    const com=instllObject.com;
                    const potter=parseFloat(instllObject.potter);
                    const a=bound.getWeight(com, potter).replace(/[\x00-\x1f]+/g, '');
                    const b=(parseFloat(String(a).replace(/[^0-9,.]/ig, ""))).toFixed(2)
                    if(b && b !=='NaN' && b !== 'undefined' && b !== 'null'   ){
                        if(a.indexOf("-")!=-1){
                            this.$set(this.form , 'count' , -Number(b))
                        }else{
                            this.$set(this.form , 'count' , b)
                        }
                    }else{
                         this.$set(this.form , 'count' , 0.00)
                    }
                    this.times=setInterval(() => {
                            setTimeout( 
                            this.weight(com,potter)
                            , 0)           
                    },200);
                }
            }
        },
        'form.count'(val){
            if(val){
                if(this.form.price){
                    this.form.total_price = (this.form.count * 1 * this.form.price).toFixed(2)
                }else{
                    this.form.total_price = 0.00
                }
                if(this.form.is_weighing == 2){
                    const instllObject= JSON.parse(localStorage.getItem('install'))?JSON.parse(localStorage.getItem('install')):{use:2,factory:undefined,type:undefined,com:undefined,potter:undefined,}
                    if(instllObject.use==2){
                        this.$set(this.form , 'count' , this.getTofixed(val))
                    }
                }else{
                    this.$set(this.form , 'count' , this.getInteger(val))
                }
            }else{
                this.form.total_price = 0.00
            }
        },
        'form.price'(val){
            if(val){
                if(this.form.count){
                    this.form.total_price = (this.form.count * 1 * this.form.price).toFixed(2)
                }else{
                    this.form.total_price = 0.00
                }
                this.$set(this.form , 'price' , this.getTofixed(val))
            }else{
                this.form.total_price = 0.00
            }
            
        },
        'form.num'(val){
            this.$set(this.form , 'num' , this.getInteger(val))
        },
    },
    components:{ numCode },
    mounted(){
        this.searchHandle()
    },
    methods:{
        weight(com,potter){
            const a=bound.getWeight(com, potter).replace(/[\x00-\x1f]+/g, '');
            const b=(parseFloat(String(a).replace(/[^0-9,.]/ig, ""))).toFixed(2)
            if(b && b !=='NaN' && b !== 'undefined' && b !== 'null'   ){
                if(a.indexOf("-")!=-1){
                    this.$set(this.form , 'count' , -Number(b))
                    this.disabled=true
                }else{
                    this.$set(this.form , 'count' , b)
                    this.disabled=true
                }
            }else{
                this.$set(this.form , 'count' , 0.00)
                this.disabled=true
            }
        },
        searchHandle(){
            this.searchForm.keywords = this.value
            goodsList(this.searchForm).then(res => {
                if(res.data.status === 200){
                    let list = res.data.data.list.data
                    list.forEach(item => {
                        if(item.spec_type == 20){
                            let price = 0
                            JSON.parse(item.spec_content).forEach((ele,i) => {
                                if(i == 0){
                                    price = ele.spec_price
                                }else{
                                    price = (price > ele.spec_price ? ele.spec_price : price)
                                }
                            })
                            item.goods_price = Number(price).toFixed(2)
                        }
                    })
                    this.tableData = list
                    this.searchForm.total = res.data.data.list.total
                }
            })
        },
        pageChange(page){
            console.log(page);
            this.searchForm.page = page
            this.searchHandle()
        },
        clickGoods(item){
            this.visible = true
            this.checkedGoods = item
            this.form = {
                goods_name:item.goods_name,
                is_weighing:item.is_weighing,
                count:1,
                price:item.goods_price,
                total_price:item.goods_price,
                num:'1',
                spec_type:item.spec_type,
                goods_unit:item.goods_unit,
                goods_no:item.goods_no,
                category_id:item.category_id,
            }
            if(item.spec_type == 20){
                this.guigeArr = JSON.parse(item.spec_content)
            }
        },
        handleCancel(){
            this.visible = false
            
        },
        inputChange(key){
            this.keyboards = key
        },
        numChange(type){
            if(type === 'jia'){
                this.$set(this.form , 'num' , this.form.num * 1 + 1)
            }else{
                if(this.form.num > 1){
                    this.$set(this.form , 'num' , this.form.num * 1 - 1)
                }
            }
        },
        handleOk(){
            console.log(JSON.stringify(this.form))
            console.log('确定打印');
             this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    console.log(this.form);
                    this.socketApi.websocketsend({key:"preopener_tag", list:[this.form]});
                    this.handleCancel()
                }
            });
        },
        codeChange(val){
            this.$set(this.form ,this.keyboards , val)
        },
        typeChange(e){
            let i = this.guigeArr.findIndex(item => item.spec_name == e.target.value)
            this.$set(this.form , 'price' , this.guigeArr[i].spec_price)
        }
    }
}
</script>
<style lang="less" scoped>
.prepackaged{
    font-size: 1.8vh;
    .title{
        font-size: 2.1vh;
        padding: 1.5vh 1vw;
    }
    .searchForm{
        display: flex;
        align-items: center;
        padding: 0 1vw;
        span{
            padding-bottom: 1vh;
        }
    }
    .goodsBox{
        height: 70vh;
        margin: 1.2vh 1vw;
        .goods_item{
            display: inline-block;
            background: #ffebe8;
            width: 10.6vw;
            max-height: 15vw;
            margin-right: 1vw;
            margin-bottom: 1vh;
            padding: 0.6vh 0.5vw;
            border-radius: 0.5vh;
            text-align: center;
            cursor: pointer;
            font-size: 2vh;
            position: relative;
            .weighing{
                position: absolute;
                top: 0.6vh;
                right: 0.5vw;
                background: #ffc300;
                border-radius: 0.5vh;
                font-size: 1.7vh;
                padding: 0.2vh 0.5vw;
            }
            .img_box{
                img{
                    width: 9.6vw;
                    height: 9.6vw;
                    border-radius: 0.5vh;
                }
            }
            &:nth-child(7n){
                margin-right: 0;
            }
            .name{
                font-size: 2vh;
                width: 9.6vw;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .price{
                color: #d95e58;
                font-weight: 700;
            }
        }
    }
    .page{
        position: fixed;
        bottom: 2vh;
        right: 2vw;
    }
}
.listData{
    .title{
        color: #333333;
        font-size: 2vh;
        font-weight: 700;
        margin-bottom: 2vh;
    }
}
.bigForm{
    ::v-deep .ant-form{
        .ant-form-item{
            margin-bottom: 1vh;
        }
    }
    .ant-input {
        height: 4.8vh!important;
        font-size: 2vh;
    }
    .ant-radio-button-wrapper{
        height: 4.8vh;
        line-height: 4.8vh;
        font-size: 2.2vh;
        padding: 0 3vw;
    }
    .jibuqi{
        display: flex;
        align-items: center;
        margin-left: 2vw;
        color: #d95e58;
        .ant-input{
            border:none;
            width: 6vw;
            text-align: center;
            color: #d95e58;
        }
        i{
            border: 1px solid #a5a5a5;
            padding: 1vh;
            border-radius: 0.5vh;
        }
    }
}

</style>
